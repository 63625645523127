import React from 'react';
import {Button} from 'antd';
import BillingJson from './billing.json';
import AdminResource from 'rev.sdk.js/Generators/AdminResource';
import * as AppActions from '../../AppActions';

export default function MgrConsole(props) {
  return (
    <>
      <AdminResource
        {...props}
        pageContext={{
          resource: {
            ...BillingJson,
            renderHeader: () => {
              return <h2>繳費記錄</h2>;
            },
          },
        }}
        renderCustomAdminCol={(props) => {
          if (props.col.customType === 'period') {
            return (
              <Button
                type="link"
                onClick={() => {
                  AppActions.navigate(
                    `/mgr-console/billings?action=detail&id=${props.record.id}`,
                  );
                }}>
                {`${props.record.year}/${props.record.month}`}
              </Button>
            );
          }
        }}
      />
    </>
  );
}
